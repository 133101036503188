import React, { useState } from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import HomepageContent from "./HomepageContent";
import "./styles.scss";
import AliasApiFactory from "../../pets-api/AliasApiFactory";

export default function Homepage() {
  const [userAlias, setUserAlias] = useState("");

  const aliasApi = AliasApiFactory();

  aliasApi.getAlias().then((res) => {
    setUserAlias(res.data);
  });

  return (
    <AppLayout
      content={<HomepageContent userAlias={userAlias} />}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}
