import React from "react";
import { SegmentedControl } from "@amzn/awsui-components-react";

export default function TimerToggle({
  isWorking,
  selectedTimerId,
  setSelectedTimerId,
}) {
  return (
    <SegmentedControl
      selectedId={selectedTimerId}
      onChange={({ detail }) => setSelectedTimerId(detail.selectedId)}
      label="Default segmented control"
      className={"timer-toggle"}
      options={[
        { text: "Pomodoro", id: "pomodoro", disabled: isWorking },
        { text: "Short Break", id: "short-break", disabled: isWorking },
        { text: "Long Break", id: "long-break", disabled: isWorking },
      ]}
    />
  );
}
