import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ColumnLayout, Spinner } from "@amzn/awsui-components-react";
import { Pipeline } from "../../../pets-api/generated-src";
import PipelinesApiFactory from "../../../pets-api/PipelineUpdatesApiFactory";

export default function PipelineUpdateWidget() {
  const [pipelineUpdates, setPipelineUpdates] = useState<Pipeline[]>([]);
  const [isFetchedPipelineUpdates, setIsFetchedPipelineUpdates] =
    useState(false);

  const pipelinesApi = PipelinesApiFactory();

  // TODO: Add configurable topic to call api with - allows for separation of teams
  function fetchPipelineUpdates() {
    pipelinesApi.listPipelines().then((res) => {
      const sortedPipelines = res.data
        .sort((pipeline1, pipeline2) => {
          // @ts-ignore
          if (pipeline1.timestamp < pipeline2.timestamp) return 1;
          // @ts-ignore
          if (pipeline1.timestamp > pipeline2.timestamp) return -1;
          return 0;
        })
        .slice(0, 20);
      setPipelineUpdates(sortedPipelines);
      setIsFetchedPipelineUpdates(true);
    });
  }

  function mockPipelineUpdates() {
    setPipelineUpdates([
      {
        topic: "arn:aws:sns:us-west-2:793581593057:PipelineSnsWatcherTopic",
        message_id: "95df01b4-ee98-5cb9-9903-4c221d41eb5e",
        message:
          "Heres your Pipeline Report!\n:white_check_mark: WolfgangChargebackViewConfig has been green for at least 33 minutes\n:honeybee: Blocked for 0 working hours this year. (0% of Availability Goal)\n:trophy: Its a miracle! We have 0 pipelines blocked. Nice work on-call! :thumbsup:",
        timestamp: "2022-09-08 20:36",
      },
    ]);
    setIsFetchedPipelineUpdates(true);
  }

  useEffect(() => {
    if (pipelineUpdates.length < 1 && !isFetchedPipelineUpdates) {
      fetchPipelineUpdates();
    }
  }, []);

  const message = `Heres your Pipeline Report!\n:white_check_mark: ${String.fromCodePoint(
    0x1f42e
  )} WolfgangChargebackViewConfig has been green for at least 33 minutes\n:honeybee: Blocked for 0 working hours this year. (0% of Availability Goal)\n:trophy: Its a miracle! We have 0 pipelines blocked. Nice work on-call! :thumbsup:`;

  function parseMessage(message: string) {
    return message
      .replace(/:thumbsup:/g, String.fromCodePoint(0x1f44d))
      .replace(/:white_check_mark:/g, String.fromCodePoint(0x2705))
      .replace(/:honeybee:/g, String.fromCodePoint(0x1f41d))
      .replace(/:trophy:/g, String.fromCodePoint(0x1f3c6))
      .replace(/:fire:/g, String.fromCodePoint(0x1f525))
      .replace(/:turtle:/g, String.fromCodePoint(0x1f422))
      .replace(/:dog:/g, String.fromCodePoint(0x1f436))
      .replace(/:tropical_fish:/g, String.fromCodePoint(0x1f420))
      .replace(/:bangbang:/g, String.fromCodePoint(0x203C))
      .replace(/:cat:/g, String.fromCodePoint(0x1F408))
      .replace(/:koala:/g, String.fromCodePoint(0x1F428))
      .replace(/:exclamation:/g, String.fromCodePoint(0x2757))
      .replace(/:fruppy_happy:/g, String.fromCodePoint(0x1f603) + "\n");
  }

  return (
    <div className={"pipeline-updates-content"}>
      {!isFetchedPipelineUpdates && <Spinner size={"large"} />}
      <ColumnLayout borders="horizontal">
        {pipelineUpdates.map((pipelineUpdate) => {
          return (
            <div key={pipelineUpdate.message_id}>
              <span>
                <b>{pipelineUpdate.timestamp} &nbsp;</b>
              </span>
              <span role="img" className={"pipeline-update-message"}>
                {parseMessage(pipelineUpdate.message)}
              </span>
            </div>
          );
        })}
      </ColumnLayout>
    </div>
  );
}
