import React, { useEffect, useState } from "react";
import Button from "@amzn/awsui-components-react/polaris/button";

export default function Timer({
  isWorking,
  selectedTimerId,
  onButtonClickHandler,
  stopTask,
  completeTask,
}) {
  function getTimeLeft() {
    if (selectedTimerId === "short-break") return 300;
    else if (selectedTimerId === "long-break") return 900;
    else return 1500;
  }
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  const [stateTimerStarted, setStateTimerStarted] = useState(isWorking);

  const timerCompleteCallback = () => {
    completeTask();
    restartTimer();
  };

  const [seconds, setSeconds] = useTimer(
    stateTimerStarted,
    timeLeft,
    timerCompleteCallback
  );

  useEffect(() => {
    setTimeLeft(getTimeLeft);
  }, [selectedTimerId]);

  useEffect(() => {
    setStateTimerStarted(isWorking);
  }, [isWorking]);

  useEffect(() => {
    setSeconds(timeLeft);
  }, [timeLeft, setSeconds]);

  function restartTimer() {
    setTimeLeft(getTimeLeft());
    setSeconds(timeLeft);
    setStateTimerStarted(false);
    stopTask();
  }

  const timerString = formatSecondsIntoMinutesAndSeconds(seconds);
  return (
    <>
      <div className="timer-label">{timerString}</div>
      <div className={"timer-button"}>
        <Button
          iconName={isWorking ? "close" : "caret-right-filled"}
          variant={isWorking ? "normal" : "primary"}
          onClick={() => onButtonClickHandler()}
        >
          {isWorking ? "Stop" : "Start"}
        </Button>
      </div>
      <div className={"restart-button"}>
        <Button variant="link" onClick={() => restartTimer()}>
          Restart
        </Button>
      </div>
    </>
  );
}

const useTimer = (timerStarted, initialSeconds, taskCompletedCallback) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let timer;

    if (timerStarted) {
      if (seconds === 0) {
        taskCompletedCallback();
      } else if (seconds > 0) {
        timer = setInterval(() => {
          // could have used setSeconds(prev => prev - 1) and removed the seconds dependency
          // but this can lead to the timers being out of sync
          setSeconds(seconds - 1);
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timerStarted, seconds, taskCompletedCallback]);

  return [seconds, setSeconds];
};

function padStartWithZeros(value, totalStringSize) {
  return value.toString().padStart(totalStringSize, "0");
}

export function formatSecondsIntoMinutesAndSeconds(value) {
  const seconds = padStartWithZeros(value % 60, 2);
  const minutes = padStartWithZeros(Math.floor(value / 60), 2);
  return [minutes, seconds].join(":");
}
