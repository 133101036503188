import React, { useState } from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Input from "@amzn/awsui-components-react/polaris/input";
import Form from "@amzn/awsui-components-react/polaris/form";
import { ButtonDropdown } from "@amzn/awsui-components-react";

export default function HomepageHeader({
  userAlias,
  toggleEditing,
  isEditing,
  setIsEditWidgetModalVisible,
}) {
  return (
    <Header
      className="custom-top-bar-header"
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="m">
          <SearchAmazonBar />
          <ButtonDropdown
            items={[
              { text: "Add/Delete", id: "addWidget", disabled: false },
              { text: "Move", id: "moveWidget", disabled: false },
            ]}
            onItemClick={(event) => {
              if (event.detail.id === "addWidget") {
                setIsEditWidgetModalVisible(true);
              } else if (event.detail.id === "moveWidget") {
                toggleEditing();
              }
            }}
            disabled={isEditing}
          >
            {isEditing ? "Editing..." : "Edit Widgets"}
          </ButtonDropdown>
          {isEditing && (
            <Button
              iconName={"close"}
              formAction={"none"}
              onClick={toggleEditing}
              variant="icon"
            />
          )}
        </SpaceBetween>
      }
    >
      <span style={{ fontFamily: "monospace" }}>{userAlias}</span> SDE Dashboard
    </Header>
  );
}

export function SearchAmazonBar() {
  const [searchBarText, setSearchBarText] = useState("");

  function searchAmazon() {
    if (searchBarText) {
      window.open(
        `https://is.amazon.com/search/ALL?q=${searchBarText}&sort=SCORE&sortOrder=DESC&autocompleted=false`
      );
      setSearchBarText("");
    }
  }

  function updateSearchBar(event) {
    if (event && event.detail && event.detail.value)
      setSearchBarText(event.detail.value);
  }

  function updateKeyDown(event) {
    if (event && event.detail && event.detail.key === "Enter") searchAmazon();
  }

  return (
    <Form
      className={"search-amazon-form"}
      actions={
        <SpaceBetween direction="horizontal" size="xxxs">
          <Input
            value={searchBarText}
            ariaRequired={true}
            placeholder="Search Amazon"
            onChange={(event) => updateSearchBar(event)}
            onKeyDown={(event) => updateKeyDown(event)}
          />

          <Button
            iconName="search"
            variant="primary"
            onClick={() => searchAmazon()}
          />
        </SpaceBetween>
      }
    />
  );
}
