import React, { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import HomepageHeader from "./HomepageHeader";
import Widget from "./Widget";
import TimerWidget from "../widgets/pomodoro/PomodoroTimerWidget";
import WorldClockWidget from "../widgets/worldclock/WorldClockWidget";
import SimListWidget from "../widgets/simlist/SimListWidget";
import CodeEditorWidget from "../widgets/editor/CodeEditorWidget";
import FavoriteAwsAccountsWidget from "../widgets/awsaccounts/FavoriteAwsAccountsWidget";
import PipelinesWidget from "../widgets/pipelines/PipelinesWidget";
import EditWidgetModal from "./EditWidgetModal";
import CodeReviewUpdatesWidget from "../widgets/crs/CodeReviewUpdatesWidget";
import PipelineUpdateWidget from "../widgets/pipelines/PipelineUpdateWidget";

const initialWidgetIds = [
  "worldClock",
  "pomodoro",
  "simList",
  "codeEditor",
  "awsAccounts",
  "pipelines",
  "crs",
  "pipelineUpdates",
];

const initialLayouts = {
  lg: [
    { w: 3, h: 6, x: 3, y: 6, i: "worldClock", static: true },
    { w: 3, h: 6, x: 6, y: 0, i: "pomodoro", static: true },
    { w: 6, h: 6, x: 0, y: 0, i: "simList", static: true },
    { w: 6, h: 6, x: 6, y: 6, i: "codeEditor", static: true },
    { w: 3, h: 6, x: 9, y: 0, i: "awsAccounts", static: true },
    { w: 3, h: 6, x: 0, y: 6, i: "pipelines", static: true },
    { w: 6, h: 6, x: 0, y: 12, i: "crs", static: true },
    { w: 3, h: 6, x: 6, y: 12, i: "pipelineUpdates", static: true },
  ],
};

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function HomePageContent({ userAlias }) {
  const [widgets, setWidgets] = useState(
    getFromLS("widgetLS", "widgets") || initialWidgetIds
  );
  const [isEditing, setIsEditing] = useState(false);
  const [layouts, setLayouts] = useState(
    getFromLS("layoutLS", "layouts") || initialLayouts
  );
  const [isEditWidgetModalVisible, setIsEditWidgetModalVisible] =
    useState(false);

  const componentList = {
    worldClock: (
      <Widget
        name="World Clock"
        component={WorldClockWidget}
        isEditing={isEditing}
      />
    ),
    pomodoro: (
      <Widget
        name="Pomodoro Timer"
        component={TimerWidget}
        isEditing={isEditing}
      />
    ),
    simList: (
      <Widget name="Sim List" component={SimListWidget} isEditing={isEditing} />
    ),
    codeEditor: (
      <Widget
        name="Code Editor"
        component={CodeEditorWidget}
        isEditing={isEditing}
      />
    ),
    awsAccounts: (
      <Widget
        name="Favorite AWS Accounts"
        component={FavoriteAwsAccountsWidget}
        isEditing={isEditing}
      />
    ),
    pipelines: (
      <Widget
        name="Your Pipelines (hardcoded)"
        component={PipelinesWidget}
        isEditing={isEditing}
      />
    ),
    crs: (
      <Widget
        name="Your Team CRs"
        component={CodeReviewUpdatesWidget}
        isEditing={isEditing}
      />
    ),
    pipelineUpdates: (
      <Widget
        name="PipelineUpdates"
        component={PipelineUpdateWidget}
        isEditing={isEditing}
      />
    ),
  };

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const toggleEditing = () => {
    setLayouts({
      ...layouts,
      lg: layouts["lg"].map((layout) => {
        return { ...layout, static: isEditing };
      }),
    });
    setIsEditing(!isEditing);
    saveToLS("layoutLS", "layouts", {
      ...layouts,
      lg: layouts["lg"].map((layout) => {
        return { ...layout, static: true };
      }),
    });
  };

  // TODO: Set layouts to user defined layouts instead of initialLayouts on save of add/remove
  const saveLayoutsToLS = (selectedWidgets) => {
    setWidgets(selectedWidgets);
    setIsEditWidgetModalVisible(false);
    setLayouts(initialLayouts);
    saveToLS("layoutLS", "layouts", initialLayouts);
    saveToLS("widgetLS", "widgets", selectedWidgets);
  };

  return (
    <>
      <HomepageHeader
        userAlias={userAlias}
        toggleEditing={toggleEditing}
        isEditing={isEditing}
        setIsEditWidgetModalVisible={setIsEditWidgetModalVisible}
      />
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        onLayoutChange={onLayoutChange}
      >
        {widgets.map((key) => (
          <div key={key}>{componentList[key]}</div>
        ))}
      </ResponsiveGridLayout>
      <EditWidgetModal
        setIsEditWidgetModalVisible={setIsEditWidgetModalVisible}
        isEditWidgetModalVisible={isEditWidgetModalVisible}
        allWidgets={initialWidgetIds}
        currentWidgets={widgets}
        saveLayoutsToLS={saveLayoutsToLS}
      />
    </>
  );
}

function getFromLS(namespace, key) {
  let ls = {};
  if (global.localStorage) {
    try {
      // @ts-ignore
      ls = JSON.parse(global.localStorage.getItem(namespace)) || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(namespace, key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      namespace,
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
