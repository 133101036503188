import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";

export default function Widget({ name, component: Item, isEditing = false }) {
  return (
    <Container
      className={`custom-widget-container ${
        isEditing ? "edit-widgets-cursor" : ""
      }`}
      header={<Header variant="h2">{name}</Header>}
    >
      <Item />
    </Container>
  );
}
