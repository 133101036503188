import React, { useEffect, useState } from "react";

import SimApiFactory from "../../../pets-api/SimApiFactory";
import { Sim } from "../../../pets-api/generated-src";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import Header from "@amzn/awsui-components-react/polaris/header";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { EmptyState, getMatchesCountText } from "../../table/table-config";
import Link from "@amzn/awsui-components-react/polaris/link";
import ReactTooltip from "react-tooltip";

export default function SimListWidget() {
  const [assignedSimList, setAssignedSimList] = useState<Sim[]>([]);
  const [isFetchedSims, setIsFetchedSims] = useState(false);
  const simApi = SimApiFactory();

  function fetchAssignedSims() {
    simApi.listSims().then((res) => {
      const sortedSims = res.data
        .sort((sim1, sim2) => {
          // @ts-ignore
          if (sim1.last_updated_date < sim2.last_updated_date) return 1;
          // @ts-ignore
          if (sim1.last_updated_date > sim2.last_updated_date) return -1;
          return 0;
        })
        .slice(0, 20);
      setAssignedSimList(sortedSims);
      setIsFetchedSims(true);
    });
  }

  function mockAssignedSims() {
    setAssignedSimList([
      {
        id: "P68207464",
        title: "[CASA/VCOV] E2E Test",
        last_updated_date: "01-21-2022",
        remaining_points: 4,
      },
      {
        id: "P69221169",
        title:
          "[CASA/VCOV] Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        last_updated_date: "01-21-2022",
        remaining_points: 5,
      },
    ]);
    setIsFetchedSims(true);
  }

  useEffect(() => {
    if (assignedSimList.length < 1 && !isFetchedSims) {
      fetchAssignedSims();
    }
  }, []);

  const { items, actions, filteredItemsCount, collectionProps, filterProps } =
    useCollection(assignedSimList || [], {
      filtering: {
        empty: (
          <EmptyState
            title="No records"
            subtitle="No records to display."
            action={
              <Button onClick={() => actions.setFiltering("")}>
                Clear filter
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering("")}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      sorting: {},
      selection: {},
    });

  const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Sim>[] = [
    {
      id: "simId",
      header: "Id",
      cell: (item) => (
        <Link
          variant="primary"
          external
          href={`https://sim.amazon.com/issues/${item.id}`}
        >
          {item.id}
        </Link>
      ),
      width: "140px",
      minWidth: "140px",
      maxWidth: "140px",
    },
    {
      id: "simTitle",
      header: "Title",
      cell: (item) => (
        <>
          <a
            data-tip="React-tooltip"
            data-for={"simTitleTooltip" + item.id}
            style={{ cursor: "pointer" }}
          >
            {item.title}
          </a>
          <ReactTooltip
            id={"simTitleTooltip" + item.id}
            type="dark"
            effect="solid"
            multiline={true}
          >
            {item.title}
          </ReactTooltip>
        </>
      ),
      minWidth: "180px",
      maxWidth: "180px",
    },
    {
      id: "pointsRemaining",
      cell: (item) => item.remaining_points,
      header: "Points Remain",
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      id: "lastUpdated",
      cell: (item) => item.last_updated_date?.slice(0, 10),
      header: "Last Updated",
      maxWidth: "70px",
    },
  ];

  return (
    <Table
      {...collectionProps}
      loading={!isFetchedSims}
      loadingText="Loading SIMs assigned to you..."
      header={
        <Header
          counter={`(${assignedSimList.length})`}
          actions={
            <Button
              iconName="refresh"
              onClick={() => {
                setIsFetchedSims(false);
                fetchAssignedSims();
              }}
              variant="primary"
              disabled={!isFetchedSims}
            >
              Refresh
            </Button>
          }
        >
          Assigned To You
        </Header>
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter records"
        />
      }
    />
  );
}
