import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";

const worldClocks = [
  { id: "America/Los_Angeles", name: "Seattle" },
  { id: "America/New_York", name: "New York" },
  { id: "Asia/Calcutta", name: "Bangalore" },
  { id: "Asia/Tokyo", name: "Tokyo" },
  { id: "Europe/London", name: "London" },
  { id: "Australia/Melbourne", name: "Melbourne" },
];

export default function WorldClockWidget() {
  const [time, setTime] = useState(Date.now());

  // Update the time every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Box>
        <Grid
          gridDefinition={[
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
          ]}
          disableGutters={true}
        >
          {worldClocks.map((clock) => {
            return (
              <SpaceBetween key={clock.id} size={"xxs"}>
                <p className={"clock-time"}>{clock.name}</p>
                <p className={"clock-city"}>
                  {new Date().toLocaleString("en-US", {
                    timeZone: clock.id,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </SpaceBetween>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}
