/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Petstore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Cr
 */
export interface Cr {
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'cr_id': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'cr_author': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'update_entity'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Cr
     */
    'packages': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'revision': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof Cr
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'tag'?: string;
}
/**
 * 
 * @export
 * @interface Pipeline
 */
export interface Pipeline {
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface Sim
 */
export interface Sim {
    /**
     * 
     * @type {string}
     * @memberof Sim
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Sim
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof Sim
     */
    'remaining_points'?: number;
    /**
     * 
     * @type {string}
     * @memberof Sim
     */
    'last_updated_date'?: string;
}

/**
 * AliasApi - axios parameter creator
 * @export
 */
export const AliasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets current user alias from midway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlias: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AliasApi - functional programming interface
 * @export
 */
export const AliasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AliasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets current user alias from midway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlias(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlias(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AliasApi - factory interface
 * @export
 */
export const AliasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AliasApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets current user alias from midway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlias(options?: any): AxiosPromise<string> {
            return localVarFp.getAlias(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AliasApi - object-oriented interface
 * @export
 * @class AliasApi
 * @extends {BaseAPI}
 */
export class AliasApi extends BaseAPI {
    /**
     * 
     * @summary Gets current user alias from midway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliasApi
     */
    public getAlias(options?: AxiosRequestConfig) {
        return AliasApiFp(this.configuration).getAlias(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrsApi - axios parameter creator
 * @export
 */
export const CrsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all CR Updates
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrs: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrsApi - functional programming interface
 * @export
 */
export const CrsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all CR Updates
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCrs(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Cr>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCrs(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrsApi - factory interface
 * @export
 */
export const CrsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrsApiFp(configuration)
    return {
        /**
         * 
         * @summary List all CR Updates
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrs(limit?: number, options?: any): AxiosPromise<Array<Cr>> {
            return localVarFp.listCrs(limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrsApi - object-oriented interface
 * @export
 * @class CrsApi
 * @extends {BaseAPI}
 */
export class CrsApi extends BaseAPI {
    /**
     * 
     * @summary List all CR Updates
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrsApi
     */
    public listCrs(limit?: number, options?: AxiosRequestConfig) {
        return CrsApiFp(this.configuration).listCrs(limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PetsApi - axios parameter creator
 * @export
 */
export const PetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPets: async (pet: Pet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pet' is not null or undefined
            assertParamExists('createPets', 'pet', pet)
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePetById: async (petId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('deletePetById', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPetById: async (petId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('showPetById', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetsApi - functional programming interface
 * @export
 */
export const PetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPets(pet: Pet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPets(pet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePetById(petId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePetById(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPets(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPets(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showPetById(petId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showPetById(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PetsApi - factory interface
 * @export
 */
export const PetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PetsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPets(pet: Pet, options?: any): AxiosPromise<void> {
            return localVarFp.createPets(pet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePetById(petId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePetById(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets(limit?: number, options?: any): AxiosPromise<Array<Pet>> {
            return localVarFp.listPets(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPetById(petId: number, options?: any): AxiosPromise<Pet> {
            return localVarFp.showPetById(petId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PetsApi - object-oriented interface
 * @export
 * @class PetsApi
 * @extends {BaseAPI}
 */
export class PetsApi extends BaseAPI {
    /**
     * 
     * @summary Create a pet
     * @param {Pet} pet A *Pet* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public createPets(pet: Pet, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).createPets(pet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Pet by id
     * @param {number} petId The id of the pet to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public deletePetById(petId: number, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).deletePetById(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all pets
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public listPets(limit?: number, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).listPets(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific pet
     * @param {number} petId The id of the pet to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public showPetById(petId: number, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).showPetById(petId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PipelinesApi - axios parameter creator
 * @export
 */
export const PipelinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all Pipeline Updates
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPipelines: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pipelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PipelinesApi - functional programming interface
 * @export
 */
export const PipelinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PipelinesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all Pipeline Updates
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPipelines(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pipeline>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPipelines(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PipelinesApi - factory interface
 * @export
 */
export const PipelinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipelinesApiFp(configuration)
    return {
        /**
         * 
         * @summary List all Pipeline Updates
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPipelines(limit?: number, options?: any): AxiosPromise<Array<Pipeline>> {
            return localVarFp.listPipelines(limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PipelinesApi - object-oriented interface
 * @export
 * @class PipelinesApi
 * @extends {BaseAPI}
 */
export class PipelinesApi extends BaseAPI {
    /**
     * 
     * @summary List all Pipeline Updates
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public listPipelines(limit?: number, options?: AxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).listPipelines(limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SimsApi - axios parameter creator
 * @export
 */
export const SimsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all sims
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSims: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SimsApi - functional programming interface
 * @export
 */
export const SimsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SimsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all sims
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSims(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sim>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSims(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SimsApi - factory interface
 * @export
 */
export const SimsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SimsApiFp(configuration)
    return {
        /**
         * 
         * @summary List all sims
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSims(limit?: number, options?: any): AxiosPromise<Array<Sim>> {
            return localVarFp.listSims(limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SimsApi - object-oriented interface
 * @export
 * @class SimsApi
 * @extends {BaseAPI}
 */
export class SimsApi extends BaseAPI {
    /**
     * 
     * @summary List all sims
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimsApi
     */
    public listSims(limit?: number, options?: AxiosRequestConfig) {
        return SimsApiFp(this.configuration).listSims(limit, options).then((request) => request(this.axios, this.basePath));
    }
}


