import React, { useEffect, useState } from "react";
import "./styles.scss";
import CrsApiFactory from "../../../pets-api/CrsApiFactory";
import Link from "@amzn/awsui-components-react/polaris/link";
import { Cr } from "../../../pets-api/generated-src";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EmptyState, getMatchesCountText } from "../../table/table-config";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import ReactTooltip from "react-tooltip";
import Header from "@amzn/awsui-components-react/polaris/header";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";

export default function CodeReviewUpdatesWidget() {
  const [crUpdates, setCrUpdates] = useState<Cr[]>([]);
  const [isFetchedCrs, setIsFetchedCrs] = useState(false);

  const crsApi = CrsApiFactory();

  // TODO: Add configurable topic to call api with - allows for separation of teams
  function fetchCrs() {
    crsApi.listCrs().then((res) => {
      const sortedCrs = res.data
        .sort((cr1, cr2) => {
          if (cr1.timestamp < cr2.timestamp) return 1;
          if (cr1.timestamp > cr2.timestamp) return -1;
          return 0;
        })
        .slice(0, 20);

      setCrUpdates(sortedCrs);
      setIsFetchedCrs(true);
    });
  }

  function mockCrs() {
    setCrUpdates([
      {
        topic: "",
        message_id: "1",
        cr_id: "CR-75262201",
        cr_author: "USER:brucewayne",
        update_entity: "covadam",
        packages: ["ChargebackWebsiteAssets", "ChargebackWidgetWebsite"],
        revision: "3.0",
        action: "approveReview",
        url: "https://code.amazon.com/reviews/CR-75262201/revisions/3",
        timestamp: "2022-09-08 20:36",
      },
      {
        topic: "",
        message_id: "2",
        cr_id: "CR-75262201",
        cr_author: "USER:brucewayne",
        update_entity: "covadam",
        packages: ["ChargebackWebsiteAssets", "ChargebackWidgetWebsite"],
        revision: "3.0",
        action: "publishReview",
        url: "https://code.amazon.com/reviews/CR-75262201/revisions/3",
        timestamp: "2022-09-08 20:36",
      },
      {
        topic: "",
        message_id: "3",
        cr_id: "CR-75262201",
        cr_author: "USER:brucewayne",
        update_entity: "covadam",
        packages: ["ChargebackWebsiteAssets", "ChargebackWidgetWebsite"],
        revision: "3.0",
        action: "closeReview",
        url: "https://code.amazon.com/reviews/CR-75262201/revisions/3",
        timestamp: "2022-09-08 20:36",
      },
      {
        topic: "",
        message_id: "4",
        cr_id: "CR-75656281",
        cr_author: "USER:brucewayne",
        update_entity: "covadam",
        packages: ["ActionPlannerFeatureHubLambda"],
        revision: "1.0",
        action: "createReviewRevision",
        url: "https://code.amazon.com/reviews/CR-75656281/revisions/1",
        timestamp: "2022-09-07 12:45",
      },
      {
        topic: "",
        message_id: "5",
        cr_id: "CR-75659199",
        cr_author: "USER:brucewayne",
        update_entity: "covadam",
        packages: ["CloseLoopService"],
        revision: "1.0",
        action: "approveReview",
        url: "https://code.amazon.com/reviews/CR-75659199/revisions/1",
        timestamp: "2022-09-08 22:36",
      },
      {
        topic: "",
        message_id: "6",
        cr_id: "CR-75432562",
        cr_author: "USER:brucewayne",
        update_entity: "covadam",
        packages: ["ChargebackViewConfigDefinition"],
        revision: "2.0",
        action: "publishReview",
        url: "https://code.amazon.com/reviews/CR-75432562/revisions/2",
        timestamp: "2022-09-07 20:11",
      },
    ]);
    setIsFetchedCrs(true);
  }

  useEffect(() => {
    if (crUpdates.length < 1 && !isFetchedCrs) {
      fetchCrs();
    }
  }, []);

  const { items, actions, filteredItemsCount, collectionProps, filterProps } =
    useCollection(crUpdates || [], {
      filtering: {
        empty: (
          <EmptyState
            title="No records"
            subtitle="No records to display."
            action={
              <Button onClick={() => actions.setFiltering("")}>
                Clear filter
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering("")}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      sorting: {},
      selection: {},
    });

  const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Cr>[] = [
    {
      id: "crId",
      header: "Id",
      cell: (item) => (
        <Link variant="primary" external href={item.url}>
          {item.cr_id}
        </Link>
      ),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "packages",
      header: "Packages",
      cell: (item) => (
        <>
          <a
            data-tip="React-tooltip"
            data-for={"crPackagesTooltip" + item.message_id}
            style={{ cursor: "pointer" }}
          >
            {item.packages.join(",")}
          </a>
          <ReactTooltip
            id={"crPackagesTooltip" + item.message_id}
            type="dark"
            effect="solid"
            multiline={true}
          >
            {item.packages.join(",")}
          </ReactTooltip>
        </>
      ),
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "crAuthor",
      cell: (item) => item.cr_author.replace("USER:", ""),
      header: "Author",
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      id: "action",
      cell: (item) => item.action,
      header: "Action",
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      id: "crUpdater",
      cell: (item) => item.update_entity,
      header: "Update By",
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      id: "lastUpdated",
      cell: (item) => item.timestamp,
      header: "Last Updated",
      maxWidth: "70px",
    },
  ];

  return (
    <Table
      {...collectionProps}
      loading={!isFetchedCrs}
      loadingText="Loading SIMs assigned to you..."
      header={
        <Header
          counter={`(${crUpdates.length})`}
          actions={
            <Button
              iconName="refresh"
              onClick={() => {
                setIsFetchedCrs(false);
                fetchCrs();
              }}
              variant="primary"
              disabled={!isFetchedCrs}
            >
              Refresh
            </Button>
          }
        >
          Recent Updates
        </Header>
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter records"
        />
      }
    />
  );
}
