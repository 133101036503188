import React from "react";
import "./styles.scss";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Link from "@amzn/awsui-components-react/polaris/link";

// TODO: Change to https://conduit.security.a2z.com/api/accounts/favorites? using: https://w.amazon.com/bin/view/ConduitAccountService/Onboarding
const favoriteAwsAccounts = [
  { accountId: "793581593057", accountName: "Covadam-Personal" },
  { accountId: "944133713116", accountName: "ActionPlanner-NA" },
  { accountId: "631930710931", accountName: "TrmsCbProd" },
  { accountId: "510877324251", accountName: "EDM-NA" },
  { accountId: "493435121261", accountName: "LEO-Sandbox-NA" },
  { accountId: "266090766218", accountName: "EDM-Alpha" },
];

export default function FavoriteAwsAccountsWidget() {
  return (
    <>
      <Box>
        <Grid
          gridDefinition={[
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
            { colspan: { default: 6, xxs: 6 } },
          ]}
        >
          {favoriteAwsAccounts.map((account) => {
            return (
              <SpaceBetween key={account.accountId} size={"xxs"}>
                <p className={"account-name"}>{account.accountName}</p>
                <Link
                  external
                  href={`https://conduit.security.a2z.com/accounts/aws/${account.accountId}/attributes`}
                  className={"account-id"}
                >
                  {account.accountId}
                </Link>
              </SpaceBetween>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}
