import React, { useState } from "react";
import "./styles.scss";
import { Box, Modal, SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";

export default function EditWidgetModal({
  isEditWidgetModalVisible,
  setIsEditWidgetModalVisible,
  allWidgets,
  currentWidgets,
  saveLayoutsToLS,
}) {
  const [selectedWidgets, setSelectedWidgets] = useState(currentWidgets);

  const setWidgetsAsSelectedWidgets = () => {
    saveLayoutsToLS(selectedWidgets);
  };

  return (
    <Modal
      onDismiss={() => setIsEditWidgetModalVisible(false)}
      visible={isEditWidgetModalVisible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={setWidgetsAsSelectedWidgets}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Add/Delete Widgets"
      size={"small"}
    >
      {allWidgets.map((widget) => {
        return (
          <Toggle
            key={widget + "Toggle"}
            className={"edit-widgets-toggle"}
            checked={selectedWidgets.includes(widget)}
            onChange={(event) => {
              if (selectedWidgets.includes(widget) && !event.detail.checked)
                setSelectedWidgets(
                  selectedWidgets.filter(
                    (selectedWidget) => selectedWidget !== widget
                  )
                );
              else if (
                !selectedWidgets.includes(widget) &&
                event.detail.checked
              )
                setSelectedWidgets([...selectedWidgets, widget]);
            }}
          >
            {widget}
          </Toggle>
        );
      })}
    </Modal>
  );
}
