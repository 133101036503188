import React, { useState } from "react";
import TimerToggle from "./TimerToggle";
import "./styles.scss";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Input from "@amzn/awsui-components-react/polaris/input";
import Timer from "./Timer";

export default function TimerWidget() {
  const [currentTaskText, setCurrentTaskText] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [selectedTimerId, setSelectedTimerId] = React.useState("pomodoro");

  function updateCurrentTask(event) {
    if (event && event.detail && event.detail.value)
      setCurrentTaskText(event.detail.value);
  }

  function updateKeyDown(event) {
    if (event && event.detail && event.detail.key === "Enter") startTask();
  }

  function startTask() {
    setIsWorking(true);
  }

  function stopTask() {
    setIsWorking(false);
  }

  function onButtonClickHandler() {
    setIsWorking(!isWorking);
  }

  function completeTask() {
    if (selectedTimerId === "short-break") {
      setSelectedTimerId("pomodoro");
      const options = {
        body: `Back to work :)`,
      };
      new Notification("Break complete!", options);
    } else if (selectedTimerId === "pomodoro") {
      setSelectedTimerId("short-break");
      const options = {
        body: `Time is up for: ${
          currentTaskText ? currentTaskText : "Something cool!"
        }🕺 \nTake a quick break.`,
      };
      new Notification("Task complete!", options);
    }
    setCurrentTaskText("");
  }

  return (
    <div className={"pomodoro-timer"}>
      <SpaceBetween size={"l"}>
        <TimerToggle
          isWorking={isWorking}
          selectedTimerId={selectedTimerId}
          setSelectedTimerId={setSelectedTimerId}
        />

        {selectedTimerId === "pomodoro" && (
          <Input
            value={
              isWorking
                ? `Working on: ${
                    currentTaskText ? currentTaskText : "Something cool!"
                  } 🕺`
                : currentTaskText
            }
            className={"timer-task-input"}
            placeholder="What are you working on? 📌"
            disabled={isWorking}
            onChange={(event) => updateCurrentTask(event)}
            onKeyDown={(event) => updateKeyDown(event)}
          />
        )}

        <Timer
          isWorking={isWorking}
          selectedTimerId={selectedTimerId}
          onButtonClickHandler={onButtonClickHandler}
          stopTask={stopTask}
          completeTask={completeTask}
        />
      </SpaceBetween>
    </div>
  );
}
