import { CrsApi } from "./generated-src";
import { getAppSetting } from "../config/AppSettings";
import { Configuration } from "./generated-src";

export default function () {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new CrsApi(
    new Configuration({
      basePath: apiBasePath,
      baseOptions: { headers: { "Access-Control-Allow-Origin": "*" } },
    })
  );
}
