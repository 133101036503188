export interface IAppSettings {
  apiUrl: string;
  userAlias: string;
}

let cache = {};

export function getAppSetting(key: string) {
  return cache[key];
}

export function initializeAppSettings(settings: IAppSettings) {
  cache = { ...settings };
  Object.freeze(cache);
}
