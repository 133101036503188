import React from "react";
import "./styles.scss";
import { ColumnLayout, StatusIndicator } from "@amzn/awsui-components-react";
import Link from "@amzn/awsui-components-react/polaris/link";

export default function PipelinesWidget() {
  enum Status {
    Okay = "OKAY",
    Attention = "ATTENTION",
    Blocked = "BLOCKED",
    Disabled = "DISABLED",
  }

  // TODO: http://hmspipelines-beta.amazon.com/explorer/index.html & https://builderhub.corp.amazon.com/docs/pipelines/api-guide/getting-started-pipeline-historical-metrics-service.html#get-started
  const favoritePipelines = [
    { name: "ActionPlanner", status: Status.Okay },
    { name: "ChargebackActionProcessor", status: Status.Disabled },
    { name: "ChargebackWidgetWebsite", status: Status.Okay },
    { name: "DebtRecoveryService", status: Status.Blocked },
    { name: "EvaluationDecisionMapper", status: Status.Okay },
    { name: "ExternalCollectionsMessagingService", status: Status.Attention },
    { name: "FraudPaymentExceptionSinkService", status: Status.Okay },
  ];

  return (
    <>
      <ColumnLayout borders="horizontal">
        {favoritePipelines.map((pipeline) => {
          return (
            <div key={pipeline.name}>
              <Link
                href={`https://pipelines.amazon.com/pipelines/${pipeline.name}`}
                variant="primary"
                className={"pipeline-name"}
                target={"_blank"}
              >
                {pipeline.name}
              </Link>
              <span className={"pipeline-status-indicator"}>
                <StatusIndicator
                  type={
                    pipeline.status === Status.Disabled
                      ? "stopped"
                      : pipeline.status === Status.Blocked
                      ? "error"
                      : pipeline.status === Status.Attention
                      ? "warning"
                      : "success"
                  }
                >
                  {pipeline.status}
                </StatusIndicator>
              </span>
            </div>
          );
        })}
      </ColumnLayout>
    </>
  );
}
