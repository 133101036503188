import * as React from "react";
import CodeEditor from "@amzn/awsui-components-react/polaris/code-editor";
import { CodeEditorProps } from "@amzn/awsui-components-react/polaris/code-editor/interfaces";
import { useEffect } from "react";

export default () => {
  const [preferences, setPreferences] = React.useState<
    CodeEditorProps.Preferences | undefined
  >(undefined);
  const [loading, setLoading] = React.useState(false);
  const [ace, setAce] = React.useState<any>(undefined);

  useEffect(() => {
    import("ace-builds")
      .then((ace) =>
        import("ace-builds/webpack-resolver")
          .then(() => {
            ace.config.set("useStrictCSP", false);
            ace.config.set("loadWorkerFromBlob", false);
            setLoading(false);
            setAce(ace);
          })
          .catch(() => setLoading(false))
      )
      .catch(() => setLoading(false));
  }, []);

  return (
    <CodeEditor
      ace={ace}
      editorContentHeight={250}
      language="javascript"
      value="const pi = 3.14;"
      onChange={(event) => event.detail.value}
      preferences={preferences}
      onPreferencesChange={(event) => setPreferences(event.detail)}
      loading={loading}
      onEditorContentResize={() => undefined}
      i18nStrings={{
        loadingState: "Loading code editor",
        errorState: "There was an error loading the code editor.",
        errorStateRecovery: "Retry",
        editorGroupAriaLabel: "Code editor",
        statusBarGroupAriaLabel: "Status bar",
        cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
        errorsTab: "Errors",
        warningsTab: "Warnings",
        preferencesButtonAriaLabel: "Preferences",
        paneCloseButtonAriaLabel: "Close",
        preferencesModalHeader: "Preferences",
        preferencesModalCancel: "Cancel",
        preferencesModalConfirm: "Confirm",
        preferencesModalWrapLines: "Wrap lines",
        preferencesModalTheme: "Theme",
        preferencesModalLightThemes: "Light themes",
        preferencesModalDarkThemes: "Dark themes",
      }}
    />
  );
};
